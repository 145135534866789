.modalForm {
  max-height: 75vh;
  overflow: scroll;
}

.select {
  position: relative;
}
.select select {
  height: 38px;
  padding: 0px 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  overflow: hidden;
}
.select select:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.select select:focus {
  outline: none;
}
.select.hasAvatar {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.select.hasAvatar select {
  padding-left: 30px;
}
.selectSmall select {
  height: 28px;
  font-size: 14px;
  border: 1px solid transparent;
}

.inputBox {
  position: relative;
}

.datePickContainer {
  min-height: 44px;
  max-height: 44px;
  height: 44px;
}

.datePick {
  margin-bottom: 33px;
}

.datePickPopUp {
  z-index: 22;
}
