.attribute {
  max-width: 100%;
  min-width: min-content;
  height: 20px;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  border: 1px solid var(--neutralBlack7);
  background: var(--neutralWhite100);
  gap: 2px;
  display: flex;
  align-items: center;
}
