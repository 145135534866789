.attributes {
  margin: 4px 0px;
  gap: 2px;
  display: flex;
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap !important;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noWrap::-webkit-scrollbar {
  display: none;
}
