:root {
  /* white */
  --neutral-white-7: rgba(255, 255, 255, 0.07);
  --neutral-white-12: rgba(255, 255, 255, 0.12);
  --neutral-white-16: rgba(255, 255, 255, 0.16);
  --neutral-white-20: rgba(255, 255, 255, 0.2);
  --neutral-white-24: rgba(255, 255, 255, 0.24);
  --neutral-white-32: rgba(255, 255, 255, 0.32);
  --neutral-white-48: rgba(255, 255, 255, 0.48);
  --neutral-white-64: rgba(255, 255, 255, 0.64);
  --neutral-white-76: rgba(255, 255, 255, 0.76);
  --neutral-white-100: #fff;
  --color-white: #ffffff;
  /* black */
  --neutral-black-4: rgba(0, 0, 0, 0.04);
  --neutral-black-7: rgba(0, 0, 0, 0.07);
  --neutral-black-12: rgba(0, 0, 0, 0.12);
  --neutral-black-16: rgba(0, 0, 0, 0.16);
  --color-black-20: rgba(0, 0, 0, 0.2);
  --neutral-black-20: rgba(0, 0, 0, 0.2);
  --neutral-black-24: rgba(0, 0, 0, 0.24);
  --neutral-black-32: rgba(0, 0, 0, 0.32);
  --color-black-35: rgba(0, 0, 0, 0.35);
  --neutral-black-48: rgba(0, 0, 0, 0.48);
  --neutral-black-64: rgba(0, 0, 0, 0.64);
  --neutral-black-88: rgba(0, 0, 0, 0.88);
  --neutral-black-100: #000;
  --color-raisin-black: #242227;
  --color-chinese-black: #171717;
  --color-dark-charcoal: #333333;
  --color-eerie-black: #1a1a1a;
  /* blue */
  --color-violets-are-blue: #856afe;
  --color-slate-blue: #7159db;
  /* grey */
  --color-spanish-grey: #8f969f;
  --color-silver-chalice: #adadad;
  --color-granite-gray: #636363;
  --animation-curve: cubic-bezier(0.12, 0.7, 0.74, 0.71);
  /* purple */
  --deep-purple-500: #7357ff;
  --deep-purple-400: #8c73ff;
  --deep-purple-300: #9f8aff;
  --deep-purple-200: #b7a8fc;
  --deep-purple-12: rgba(134, 107, 255, 0.12);
  --deep-purple-4: rgba(134, 107, 255, 0.04);
  /* red - danger */
  --danger-600: #cf2a2a;
  --danger-500: #f03d3d;
  --danger-400: #f95e5e;
  --Red-4: #e26b77;
  /* green - success */
  --success-12: rgba(77, 223, 170, 0.12);
  --success-32: rgba(77, 223, 170, 0.32);
  --success-300: #4ddfaa;
  --success-400: #28ce92;
  --success-900: #0d1f18;

  /* ----- z-index ----- */
  --trading-documents-right-panel-z-index: 20;
  --navigation-width: 220px;
  --collapsed-navigation-width: 70px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  background: #edf1f3;
  min-height: 100vh;
  position: relative;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* react-laag layer container */
#layers {
  z-index: 22;
}

a {
  color: #01c9d5;
}

a[disabled] {
  pointer-events: none;
}

dl {
  display: flex;
  justify-content: space-between;
}

.info-value {
  font-size: 1rem;
}

.info-value,
.info-label {
  display: inline-flex;
  min-height: 30px;
  align-items: center;
}
.info-value-end {
  display: inline-flex;
  min-height: 30px;
  align-items: baseline;
}

.pageContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.info-label {
  font-size: 0.9rem;
  max-width: 150px;
  min-width: 150px;
}

.info-value--undefined,
.info-label {
  color: #808080;
}
.info-value--special {
  font-size: 1.1rem;
  color: #ec6c74;
}
.line {
  width: 10px;
  height: 1px;
  background: #808080;
  margin: 0 45px 0 0;
}
.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  height: 20px;
  line-height: 18px;
  font-size: 0.81rem;
  flex-wrap: nowrap;
  padding: 0 3px;
  min-width: fit-content;
  width: moz-fit-content;
  color: #fff;
}
.bgBlue {
  background: #01c9d5;
  color: #fff;
}

.bgPurple {
  background: #5b3fdd;
  color: #fff;
}

.bgOrange {
  background: orange;
  color: #fff;
}
.bgRed {
  background: #ec6c74;
  color: #fff;
}
.bgCoral {
  background: #ec6c74;
  color: #fff;
}
.bgYellowOrange {
  background: #d58d01;
  color: #fff;
}
.bgRedOrange {
  background: #f7743e;
  color: #fff;
}
.bgYellow {
  background: #d5cd01;
  color: #fff;
}
.bgPink {
  background: #d50180;
  color: #fff;
}
.bgGrey {
  background: #999;
  color: #fff;
}
.bgRed {
  background: #ff6e75;
  color: #fff;
}
.bgDarkred {
  background: #d50e01;
  color: #fff;
}
.bgViolet {
  background: #b284ef;
  color: #fff;
}
.bgBlueviolet {
  background: #8a2be2;
  color: #fff;
}
.bgBlueGrey {
  background: #9bb1dc;
}
.bgGreen {
  background: #01d5a2;
  color: #fff;
}
.bgDarkblue {
  background: #00008b;
  color: #fff;
}
.bgDarkcyan {
  background: #008b8b;
  color: #fff;
}
.bgMidblue {
  background: #007bff;
  color: #fff;
}
.bgCadetblue {
  background: #5f9ea0;
  color: #fff;
}
.bgBurlywood {
  background: #deb887;
  color: #fff;
}
.bgLightGrey {
  background: #ddd;
}

.bgDarkGrey {
  background: #edf1f3 !important;
}

.text-color-coral {
  color: #ec6c74;
}
.text-color-blue {
  color: #01c9d5;
}
.text-color-darkblue {
  color: #1c949d;
}
.text-color-purple {
  color: #765ee3;
}
.text-color-pink {
  color: #d50180;
}
.text-color-white {
  color: #fff;
}
.text-color-darkgrey {
  color: #333;
}
.text-color-grey {
  color: #808080;
}
.text-color-lightgrey {
  color: #bbb;
}
.text-color-red {
  color: #e87076;
}
.text-color-darkred {
  color: #c5565c;
}
.text-color-black {
  color: #333 !important;
}
.text-color-green {
  color: #01d5a2 !important;
}
.text-color-silver-chalice {
  color: var(--color-silver-chalice);
}
.text-color-black-35 {
  color: var(--color-black-35);
}
.text-color-violets-are-blue {
  color: var(--color-violets-are-blue);
}

.italic {
  font-style: italic;
}
button:focus {
  outline: 0;
}
.btn-appear-link {
  border: 0;
  background: transparent;
}
.w-70 {
  width: 70%;
}
.w-30 {
  width: 30%;
}
.w-available {
  width: -webkit-fill-available !important;
}
.blue-link {
  color: #5ec9d5;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}
.blue-link:hover {
  color: #52b8c3;
  text-decoration: none;
  background: transparent;
}
.link-no-underline {
  text-decoration-line: none;
}
.btn-ready {
  background: transparent;
  color: #333;
  border-color: #bbbbbb;
  border-radius: 50px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  line-height: 25px;
}
.btn-ready:hover {
  background: rgba(0, 0, 0, 0.03);
  color: #333;
  border-color: #ddd;
}
.btn-ready:focus {
  background: #01c9d5;
  color: #fff;
  border-color: #01c9d5;
}
.btn-ready:focus img {
  filter: contrast(0%) brightness(200%);
}
.btn-ready img {
  filter: contrast(0%) brightness(0%);
  margin-top: -2px;
  margin-right: 2px;
}
.width-available {
  width: -webkit-fill-available;
  width: -moz-available;
}
.form-section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  margin: 15px;
  padding: 25px 15px 20px;
  max-width: 1350px;
}
.form-section h5 {
  color: #999;
  font-size: 1rem;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.table-header {
  font-size: 1.1rem;
  color: #516163;
}
.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}

.max-w-100 {
  max-width: 100% !important;
}

.hr-white {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 5px 0;
}

.dividerOnDark {
  width: 0px;
  height: 20px;
  border-left: 1px solid var(--gray-5, #e0e0e0);
  background: transparent;
  border-left: 1px solid rgb(255 255 255 / 15%);
  border-radius: 4px;
  margin: 0 5px;
}

.scrollable {
  overflow: scroll;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.btnSizeMedium {
  display: inline-flex;
  align-items: center;
  height: 30px;
}
.btnSizeSmall {
  display: inline-flex;
  align-items: center;
  height: 26px;
}
.warning {
  color: #d9ad55;
  font-size: 0.75rem;
}
.cmx-1 > * + * {
  margin-left: 0.25rem !important;
}
.cmy-1 > * + * {
  margin-top: 0.25rem !important;
}
.cmx-2 > * + * {
  margin-left: 0.5rem !important;
}
.cmy-2 > * + * {
  margin-top: 0.5rem !important;
}
.cmx-3 > * + * {
  margin-left: 1rem !important;
}
.cmy-3 > * + * {
  margin-top: 1rem !important;
}
.cmx-4 > * + * {
  margin-left: 1.5rem !important;
}
.cmy-4 > * + * {
  margin-top: 1.5rem !important;
}
.cmx-5 > * + * {
  margin-left: 3rem !important;
}
.cmy-5 > * + * {
  margin-top: 3rem !important;
}

.font-grey {
  color: #738992;
}
.greyLabel {
  font-size: 0.9rem;
  color: #808080;
}
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.font-weight-semi-bold {
  font-weight: 500 !important;
}
.white-icon {
  filter: contrast(0%) brightness(200%);
}
.black-icon {
  filter: invert(18%) sepia(3%) saturate(0%) hue-rotate(233deg) brightness(95%) contrast(91%);
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 15px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-34 {
  font-size: 34px;
}
.fs-36 {
  font-size: 36px;
}

.text-grey {
  color: #808080;
}
.text-spanish-grey {
  color: #8f969f;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal !important;
}

.internalId {
  display: inline-block;
  font-size: 0.7rem;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #757575;
  margin-right: 10px;
}
.orderRedirectSignature {
  cursor: pointer;
  color: #212529;
}
.orderRedirectSignature:hover {
  color: #01c9d5;
}

.input {
  background-color: transparent;
  outline: none;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.input:focus {
  border: 1px solid #765ee3;
}
.inputSmall {
  max-width: 110px;
  min-height: none;
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  background: none;
}

.inputSmall:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-4, #9f8aff) !important;
}

.inputSmall:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-5, #8c73ff) !important;
}

.inputOnLightBg {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #333;
}

.mb-12 {
  margin-bottom: 12px;
}

.rotate180 {
  transform: rotate(180deg);
}
.line-through {
  text-decoration: line-through;
}

.mt1 {
  margin-top: 1px;
}

.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1rem;
}
.gap-4 {
  gap: 1.5rem;
}
.gap-5 {
  gap: 3rem;
}

.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatarMedium {
  height: 30px !important;
  min-height: 30px;
  width: 30px !important;
  min-width: 30px;
}

.avatarLarge {
  height: 34px;
  min-height: 34px;
  width: 34px;
  min-width: 34px;
}

.btn {
  position: relative;
  display: flex;
  min-height: 26px;
  padding: 1px;
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
.btnBase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
}
.btnBase16 img {
  display: flex;
  width: 16px;
  height: 16px;
}
.btnBase18 img {
  display: flex;
  width: 18px;
  height: 18px;
}
.btnBaseSmall {
  padding: 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12px;
}
.btnBaseMedium {
  padding: 6px 12px;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
}
.line-divider {
  width: 0px;
  height: 20px;
  border-left: 1px solid var(--gray-5, #e0e0e0);
}

.new-divider {
  margin: 0 5px;
  border-radius: 4px;
  background: transparent;
}

.lineDividerMargin {
  margin: 0 5px;
}

.separator {
  display: inline-flex;
  width: 4px;
  height: 4px;
  background: #bbb;
  border-radius: 4px;
}

.cyan-3 {
  background: var(--cyan-3, #91e8ed);
  color: var(--cyan-8, #00646a);
}
.teal-4 {
  background: var(--teal-4, #6de5b9);
  color: var(--teal-8, #1e4c40);
}
.red-2 {
  background: var(--red-2, #f7cfd3);
  color: var(--red-6, #c3291c);
}
.grey-10 {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.text-red-4 {
  color: var(--Red-4, #e26b77);
}
.text-red-6 {
  color: var(--red-6, #c3291c);
}
.text-teal-4 {
  color: var(--teal-4, #6de5b9);
}
.text-teal-6 {
  color: var(--teal-6, #55bca6);
}
.text-teal-8 {
  color: var(--teal-8, #1e4c40);
}
.text-orange-4 {
  color: var(--orange-4, #ee9e4b);
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.body-10-400 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}

.body-10-500 {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}

.body-10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}

.body-10-900 {
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}
.body-16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.body-16-800 {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 125% */
}

.body-12 {
  font-size: 12px;
  line-height: 14px;
}

.body-14 {
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.body-14-600 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.body-14-500 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.body-14-700 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.body-18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 125% */
}

.body-18-800 {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 125% */
}

.text-grey-35 {
  color: var(--black-35, rgba(0, 0, 0, 0.35));
}

.text-deep-purple-5 {
  color: var(--deep-purple-5, #8c73ff);
}

.text-deep-purple-4 {
  color: var(--Deep-Purple-4, #9f8aff);
}

.text-deep-orange-4 {
  color: var(--Deep-Orange-4, #e57458);
}
.tableHeaderGreyBg {
  background: #fbfbfb !important;
}
.text-black-6 {
  color: var(--black-6, #808080);
}
.text-black-default {
  color: #212529 !important;
}
.text-red-4 {
  color: var(--Red-4, #e26b77);
}

.new-link {
  color: var(--blue-5, #2b8efa);
  font-size: 14px;
  font-weight: 500;
}
.new-link:hover {
  color: var(--blue-5, #2b8efa);
  opacity: 0.8;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.customTooltip {
  background-color: #000;
  padding: 4px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.03rem;
}

.bg-red-1 {
  background: var(--Red-1, #fcecee);
}

.bg-yellow-1 {
  background: var(--Yellow-1, #fbf4d6);
  color: var(--Yellow-7, #a18200);
}
.bg-deep-orange-10 {
  background: var(--deep-orange-10, rgba(235, 97, 55, 0.1));
}
.bg-deep-orange-4 {
  background: var(--Deep-Orange-4, #e57458);
}
.bg-deep-orange-3 {
  background: var(--Deep-Orange-3, #f08f6d);
  color: var(--Deep-Orange-8, #6d2714);
}
.bg-deep-purple-10 {
  background: var(--deep-purple-10, rgba(134, 107, 255, 0.1));
}
.bg-teal-10 {
  background: var(--teal-10, rgba(65, 148, 136, 0.1));
}
.bg-teal-4 {
  background: var(--teal-4, #6de5b9);
  color: var(--teal-8, #1e4c40);
}

.btnBgRed {
  color: #fff;
  background: var(--Red-6, #c3291c);
}
.btnBgRed:hover {
  background: var(--Red-4, #e26b77);
}
.btnBgRed:focus {
  background: var(--Red-3, #e39e9c);
}
.btnBgRed img {
  filter: invert(1);
}

.borderTop {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.borderRight {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.borderLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.textAlignRight {
  text-align: right;
}

.panelHeaderDuringSlide {
  height: 46px;
}

.slidingPanelHeader {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 22px;
}

.slidingPanelHeaderShadow {
  box-shadow: 0 1px 2px rgba(169, 169, 169, 0.2);
}

.slidingPanelHeaderContent {
  margin-top: -50px;
  animation: slidePanelHeaderAnimation 0.3s forwards;
}

@keyframes slidePanelHeaderAnimation {
  to {
    margin-top: 0;
  }
}

.btnDisabled {
  opacity: 0.6;
  filter: grayscale(1);
  cursor: not-allowed;
  color: rgb(51, 51, 51);
}
.btnDisabledRed:disabled {
  background: #c3291c;
  color: white;
}
.btnDisabledGreen:disabled {
  background: #6de5b9;
  color: #1e4c40;
}

.darkGreen {
  background: var(--teal-4, #6de5b9);
  color: var(--teal-8, #1e4c40);
}

@media (min-width: 1367px) {
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
}

.__react_component_tooltip {
  border: none !important;
}

.imgSX {
  display: block;
  width: 14px;
}
.imgS {
  display: block;
  width: 16px;
}
.imgM {
  display: block;
  width: 20px;
}
.no-border {
  border: none !important;
}
.no-border:focus {
  border: none !important;
}
.cut-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.firstItemHintboxPosition {
  bottom: -130% !important;
}
.narrowLetters {
  letter-spacing: -0.03rem !important;
}
.pointer-events-none {
  pointer-events: none;
}

.user-select-text {
  user-select: text !important;
}

.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollBar::-webkit-scrollbar {
  display: none;
}

.small-spinner {
  width: 20px !important;
  height: 20px !important;
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sofiaSansFont {
  font-family: "Sofia Sans", sans-serif !important;
}

.cabinFont {
  font-family: "Cabin", sans-serif !important;
}

.dashedTableRow::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background-image: linear-gradient(
    -45deg,
    var(--warning100) 5.56%,
    var(--neutralWhite100) 5.56%,
    var(--neutralWhite100) 50%,
    var(--warning100) 50%,
    var(--warning100) 55.56%,
    var(--neutralWhite100) 55.56%,
    var(--neutralWhite100) 100%
  ) !important;
  background-size: 11.22px 11.22px;
  opacity: 0.4;
}
.gap-12px {
  gap: 12px;
}

.text-underline {
  text-decoration: underline;
}

.overflow-visible {
  overflow: visible !important;
}
.outline-0 {
  outline: none;
}
