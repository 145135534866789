.field {
  cursor: pointer;
  background-color: rgb(38 38 38);
  z-index: 1;
  position: absolute;
  border-width: 2px;
  border-style: dashed;
}

.field:hover {
  border: 20px solid var(--Deep-Purple-5, #8c73ff);
}

.wavesTB {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.wave__waveletBT {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0%;
  position: absolute;
  opacity: 1;
  transform: translateY(-100%);
  animation: waveTB-1 1200ms linear infinite;
}
@keyframes waveTB-1 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
